import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.clear
import kotlinx.dom.removeClass
import kotlinx.html.dom.create
import kotlinx.html.js.span
import kotlinx.html.span
import kotlinx.html.style
import org.w3c.dom.Audio
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.asList
import org.w3c.dom.events.MouseEvent

var shakingTimer = 0
val title = document.querySelector("#title") as HTMLElement
val repeatingTasks = mutableListOf<()->Unit>()

fun updateStroke() {
    document.getElementsByClassName("stroke").asList().forEach { element: Element ->
        val text: String
        val stroke = document.createElement("span").apply {
            addClass("front")
            insertAdjacentHTML("beforeend",element.innerHTML)
            text = element.textContent?:""
        }
        element.clear()
        element.insertBefore(stroke,null)
        element.insertAdjacentText("beforeend",text)
        element.removeClass("stroke")
    }
}

fun main() {
    updateStroke()

    window.setInterval({loop()},100)

    repeatingTasks.add {
        shakingTimer = maxOf(-1, shakingTimer - 1)
        if (shakingTimer == 0) { title.removeClass("shaking") }
    }

    val punchSounds = listOf("./media/sound/punch-heavy1.mp3","./media/sound/punch-heavy2.mp3","./media/sound/punch-heavy3.mp3")
        .map { Audio(it) }.onEach { audio -> audio.load() }

    title.onclick = { _: MouseEvent ->
        title.removeClass("shaking")
        title.addClass("shaking")
        shakingTimer = 2
        punchSounds.filter { it.paused }.ifEmpty { listOf(punchSounds.random().also { it.pause();it.currentTime=0.0 }) }.random().play()
        val damage = (0..100).random()
        val fontSize = maxOf(minOf(damage/3.0,35.0),30.0)
        val damageSpan = document.create.span("damageCount") {
            span("text") {
                +"$damage"
                span {
                    +"DAMAGE!"
                    style="font-size: ${fontSize/1.7}px"
                }
            }
            span("stroke") {
                +"$damage"
                span {
                    +"DAMAGE!"
                    style="font-size: ${fontSize/1.7}px"
                }
            }
        }
        damageSpan.style.fontSize = "${fontSize}px"
        damageSpan.addClass("fadeout")
        title.insertAdjacentElement("afterbegin",damageSpan)
        window.setTimeout({ damageSpan.remove() },1900)
        ""
    }
}

fun loop() {
    repeatingTasks.forEach { it.invoke() }
}